"use client";

import { Theme, ThemeOptions, ThemeProvider, createTheme } from "@mui/material";
import { createContext, useCallback, useState } from "react";
const defaultTheme = createTheme({
   palette: {
      primary: {
         main: "#1A1A1A"
      },
      secondary: {
         main: "#1A1A1A"
      }
   },
   typography(palette) {
      return {
         // fontFamily: ["Chivo", "Inter", "sans-serif"].join(","),
         // fontFamily: ["Inter", "sans-serif"].join(","),
         fontFamily: ["var(--font-dmsans)", "DM_Sans", "sans-serif"].join(","),
         allVariants: {
            color: "text-slate-900"
         },
         h1: {
            fontSize: "2.5rem",
            fontWeight: 700
         },
         h2: {
            fontSize: "2rem",
            fontWeight: 700
         },
         h3: {
            fontSize: "1.75rem",
            fontWeight: 700
         },
         h4: {
            fontSize: "1.5rem",
            fontWeight: 700
         },
         h5: {
            fontSize: "1.25rem",
            fontWeight: 700
         },
         h6: {
            fontSize: "1rem",
            fontWeight: 700
         },
         body1: {
            fontSize: "1rem",
            fontWeight: 500
         },
         body2: {
            fontSize: "0.875rem",
            fontWeight: 500
         },
         button: {
            fontSize: "1rem",
            fontWeight: 700
         },
         caption: {
            fontSize: "0.75rem",
            fontWeight: 500
         },
         overline: {
            fontSize: "0.625rem",
            fontWeight: 500
         },
         subtitle1: {
            fontSize: "1rem",
            fontWeight: 500
         },
         subtitle2: {
            fontSize: "0.875rem",
            fontWeight: 500
         }
      };
   },
   components: {
      // Name of the component ⚛️
      MuiButtonBase: {
         defaultProps: {
            // The default props to change
            disableRipple: true // No more ripple, on the whole application 💣!
         }
      },
      MuiTextField: {
         defaultProps: {
            style: {
               font: "unset",
               padding: 0,
               margin: 0,
               outline: 0,
               border: 0,
               boxShadow: "none",
               // textWrap: "wrap",
               borderColor: "white",
               outlineColor: "white",
               outlineOffset: 0,
               outlineWidth: 0,
               outlineStyle: "none",
               borderBottom: 0,
               borderImageWidth: 0
            }
         }
      },
      MuiInputBase: {
         defaultProps: {
            style: {
               font: "unset",
               padding: 0,
               margin: 0,
               outline: 0,
               border: 0,
               boxShadow: "none",
               // textWrap: "wrap",
               borderColor: "white",
               outlineColor: "white",
               outlineOffset: 0,
               outlineWidth: 0,
               outlineStyle: "none",
               borderBottom: 0,
               borderImageWidth: 0
            }
         }
      },
      MuiInput: {
         defaultProps: {
            style: {
               font: "unset",
               padding: 0,
               margin: 0,
               outline: 0,
               border: 0,
               boxShadow: "none",
               // textWrap: "wrap",
               borderColor: "white",
               outlineColor: "white",
               outlineOffset: 0,
               outlineWidth: 0,
               outlineStyle: "none",
               borderBottom: 0,
               borderImageWidth: 0
            }
         }
      }
   }
});

interface MuiThemeProviderProps {
   children: React.ReactNode;
}
export default function MuiThemeProvider({ children }: MuiThemeProviderProps) {
   const { theme, setMuiTheme } = useMuiTheme({});
   return (
      <MuiThemeContext.Provider value={{ theme }}>
         <ThemeProvider theme={theme}>
            <MuiThemeSetterContext.Provider value={{ setMuiTheme }}>{children}</MuiThemeSetterContext.Provider>
         </ThemeProvider>
      </MuiThemeContext.Provider>
   );
}

export type MuiThemeSetterContextType = {
   setMuiTheme: (options: ThemeOptions | undefined) => void;
};

export const MuiThemeSetterContext = createContext<MuiThemeSetterContextType>({
   setMuiTheme: () => {}
});

export type MuiThemeContextType = {
   theme: ThemeOptions;
};

export const MuiThemeContext = createContext<MuiThemeContextType>({
   theme: defaultTheme
});

export function useMuiTheme({}) {
   const [theme, set] = useState<Theme>(defaultTheme);
   //    const mountedRef = useRef<boolean>(true);

   const setMuiTheme = useCallback((options: ThemeOptions | undefined) => {
      if (!options) return;
      const theme = createTheme(options);

      set(theme);
   }, []);
   // const memoizedThemeOptions = useMemo(() => theme, [theme]);
   return { theme, setMuiTheme };
}
