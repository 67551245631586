"use client";
import Info from "app/_components_v2/todo/Info";
import useMigrationContext, { UseMigrationContextProps } from "app/_hooks/useMigrationContext";
import ILog from "app/_lib/global/Log";
import React from "react";
import { AppContext } from "./AppContext";

export default function AppProvider({ children, message, migrationDuration, migrationTimestamp }: { children: React.ReactNode } & UseMigrationContextProps) {
   const migrationValues = useMigrationContext({ message, migrationDuration, migrationTimestamp });
   // const agentMetadata = useAgent();

   const appContext = {
      ...migrationValues
      // ...agentMetadata
   };

   ILog.v("AppProvider", { appContext });
   return (
      <AppContext.Provider value={appContext}>
         {!!migrationValues.migrationMessage && <Info message={migrationValues.migrationMessage} dismissable />}
         {children}
      </AppContext.Provider>
   );
}
