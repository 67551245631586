import { DateTime } from "luxon";
import { useContext, useEffect, useMemo } from "react";

import { AuthStatusContext } from "app/(platform)/(authentication)/_contexts/AuthenticatedSessionProvider";
import useLogout from "app/(platform)/(authentication)/_hooks/useLogout";
import ILog from "app/_lib/global/Log";
import { BaseRest } from "app/_lib/rest/BaseRest";

export type UseMigrationContextProps = {
   migrationTimestamp: number | undefined;
   migrationDuration: number | undefined;
   message: string | undefined;
};
export default function useMigrationContext({ migrationTimestamp, migrationDuration, message }: UseMigrationContextProps) {
   // const [migrationDismissed, setMigrationDismissed] = useCustomSessionStorage(`migration-dismissed-${migrationTimestamp}`, false);
   // const [migrationReload, setMigrationReload] = useCustomSessionStorage(`migration-reload-${migrationTimestamp}`, false);
   // const [migrationReloadComplete, setMigrationReloadComplete] = useCustomSessionStorage(`migration-reload-complete-${migrationTimestamp}`, false);
   const logOut = useLogout();
   const { status } = useContext(AuthStatusContext);

   const { migrationActive, migrationMessage, upcomingMigration } = useMemo(() => {
      const migrationActive = BaseRest.migrationActive({ timestamp: migrationTimestamp });
      ILog.v("useMigrationContext", { migrationActive });

      if (migrationTimestamp) {
         const migrationStarts = DateTime.fromMillis(migrationTimestamp);
         const migrationEnds = migrationStarts.plus({ seconds: migrationDuration });
         // 2 days publish as default behavior
         const migrationUpcoming = DateTime.local().diff(migrationStarts).days < 2;
         // const migrationActive = DateTime.local().diff(migrationStarts).milliseconds > 0 && DateTime.local().diff(migrationEnds).milliseconds < 0;
         const humanReadableStart = migrationStarts.toFormat("LLL dd HH:mm");
         const humanReadableDuration = migrationEnds.diff(migrationStarts).toFormat(" h 'hours and' m 'minutes'");

         if (migrationUpcoming || migrationActive) {
            const finalMessage = `${message} ${humanReadableStart}, lasting for an estimated ${humanReadableDuration}`;
            return {
               migrationActive,
               migrationMessage: finalMessage,
               upcomingMigration: migrationUpcoming
            };
         }

         return {
            migrationActive: migrationActive,
            migrationMessage: undefined,
            upcomingMigration: migrationUpcoming
         };
      }

      return {
         migrationActive,
         migrationMessage: undefined,
         upcomingMigration: false
      };
   }, [migrationTimestamp, migrationDuration, message]);

   useEffect(() => {
      if (migrationActive && status === "authenticated") {
         logOut();
      }
   }, [status, migrationActive, migrationMessage, upcomingMigration]);

   // useEffect(() => {
   //    if (migrationReload && !migrationReloadComplete) {
   //       setMigrationDismissed(false);
   //       setMigrationReload(false);
   //       setMigrationReloadComplete(true);
   //       logOut();
   //    }
   // }, [migrationReload, migrationReloadComplete]);

   ILog.v("useMigrationContext", { migrationActive, migrationMessage });
   return {
      migrationActive,
      migrationMessage
   };
}
